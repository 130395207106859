<template>
  <div class="page_content page_content_category">
    <div class="content_header d-flex align-items-center">
      <span>品類管理</span>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="search_section">
          <div class="form-group d-flex align-items-center">
            <label for="">系統</label>
            <select
              id="system"
              v-model="search.system"
              v-validate="'required'"
              name="系統"
              class="form-control search_brand"
              :class="{'is-invalid': errors.has('search.系統')}"
              data-vv-scope="search"
              @change="get_main_category($event.target.value)"
            >
              <option
                value
                disabled
                selected
                hidden
              >
                系統
              </option>
              <option
                v-for="item in system_data"
                :key="item.systemCode"
                :value="item.systemCode"
              >
                {{ item.systemName }}
              </option>
            </select>
          </div>
        </div>
        <div
          v-if="category_data.length > 0"
          class="row"
        >
          <div class="col-12 col-xl-6">
            <div class="category_section">
              <div class="section_header d-flex align-items-center">
                <h3 class="mr-2 text-color_primary">
                  主分類列表
                </h3>
                <a
                  href="#"
                  class="btn btn_primary"
                  @click.prevent="toggle_modal('create', 'main')"
                >
                  <span>新增主分類</span>
                </a>
              </div>
              <div class="section_body">
                <div
                  v-show="category_data.length > 0"
                  class="table-responsive"
                >
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th class="text-center">
                          排序
                        </th>
                        <th class="text-center">
                          代號
                        </th>
                        <th>名稱</th>
                        <th class="text-center">
                          編輯
                        </th>
                        <th class="text-center">
                          狀態
                        </th>
                      </tr>
                    </thead>
                    <draggable
                      v-model="category_data"
                      tag="tbody"
                      @change="update_maincategory_order"
                    >
                      <tr
                        v-for="(main_category, index) in category_data"
                        :key="main_category.categoryCode"
                      >
                        <td class="text-center">
                          {{ index + 1 }}
                        </td>
                        <td class="text-center">
                          {{ main_category.categoryCode }}
                        </td>
                        <td>
                          <a
                            href="#"
                            class="text-color_primary"
                            @click.prevent="get_sub_category(main_category.categoryCode)"
                          >
                            {{ main_category.categoryName }}
                          </a>
                        </td>
                        <td class="text-center">
                          <a
                            href="#"
                            class="mx-1 text-color_primary"
                            @click.prevent="get_category_detail(main_category.categoryCode, 'main')"
                          >
                            <i class="icon-edit" />
                          </a>
                          <a
                            href="#"
                            class="mx-1 text-color_primary"
                            @click.prevent="delete_category(main_category.categoryCode)"
                          >
                            <i class="icon-trash" />
                          </a>
                        </td>
                        <td class="text-center">
                          <label
                            :for="`category_switch_btn_${index}`"
                            class="switch_btn"
                            :class="{ 'switch_checked': main_category.optionShowFront }"
                            @click.prevent="update_category_optionshow(main_category.categoryCode, main_category.optionShowFront)"
                          >
                            <input
                              :id="`category_switch_btn_${index}`"
                              type="checkbox"
                            >
                          </label>
                        </td>
                      </tr>
                    </draggable>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-6">
            <div class="category_section">
              <div class="section_header d-flex align-items-center">
                <h3 class="mr-2 text-color_primary">
                  次分類列表
                </h3>
                <a
                  v-if="search.main_category !== ''"
                  href="#"
                  class="btn btn_primary"
                  @click.prevent="toggle_modal('create', 'sub')"
                >
                  <span>新增次分類</span>
                </a>
              </div>
              <div
                v-show="category_data.length > 0"
                class="section_body"
              >
                <div
                  v-show="subcategory_data.length > 0"
                  class="table-responsive"
                >
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th class="text-center">
                          排序
                        </th>
                        <th class="text-center">
                          代號
                        </th>
                        <th>名稱</th>
                        <th class="text-center">
                          編輯
                        </th>
                        <th class="text-center">
                          狀態
                        </th>
                      </tr>
                    </thead>
                    <draggable
                      v-model="subcategory_data"
                      tag="tbody"
                      @change="update_subcategory_order"
                    >
                      <tr
                        v-for="(sub_category, index) in subcategory_data"
                        :key="sub_category.categorySubCode"
                      >
                        <td class="text-center">
                          {{ index + 1 }}
                        </td>
                        <td class="text-center">
                          {{ sub_category.categorySubCode }}
                        </td>
                        <td>{{ sub_category.categorySubName }}</td>
                        <td class="text-center">
                          <a
                            href="#"
                            class="mx-1 text-color_primary"
                            @click.prevent="get_category_detail(sub_category.categorySubCode, 'sub')"
                          >
                            <i class="icon-edit" />
                          </a>
                          <a
                            href="#"
                            class="mx-1 text-color_primary"
                            @click.prevent="delete_category(sub_category.categorySubCode)"
                          >
                            <i class="icon-trash" />
                          </a>
                        </td>
                        <td class="text-center">
                          <label
                            :for="`category_switch_btn_${index}`"
                            class="switch_btn"
                            :class="{ 'switch_checked': sub_category.optionShowFront }"
                            @click.prevent="update_category_optionshow(sub_category.categorySubCode, sub_category.optionShowFront)"
                          >
                            <input
                              :id="`category_switch_btn_${index}`"
                              type="checkbox"
                            >
                          </label>
                        </td>
                      </tr>
                    </draggable>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Modal -->
    <div
      id="category_edit"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div class="modal-content border-0 rounded-0">
          <div class="modal-header p-0 border-0">
            <h5
              v-show="modal_content === 'main'"
              class="modal-title"
            >
              {{ modal_mode === 'create' ? '新增主分類' : '編輯主分類' }}
            </h5>
            <h5
              v-show="modal_content === 'sub'"
              class="modal-title"
            >
              {{ modal_mode === 'create' ? '新增次分類' : '編輯次分類' }}
            </h5>
          </div>
          <div class="modal-body p-0">
            <div class="row">
              <div class="col-6">
                <div
                  v-if="modal_content === 'main'"
                  class="form-group d-flex align-items-center"
                >
                  <label for="category_code">分類代碼</label>
                  <input
                    id="category_code"
                    v-model="category_detail.categoryCode"
                    disabled
                    type="text"
                    name="分類代碼"
                    class="form-control"
                    :class="{'is-invalid': errors.has('detail.分類代碼')}"
                    data-vv-scope="detail"
                    placeholder="無需輸入"
                  >
                </div>
                <div
                  v-if="modal_content === 'sub'"
                  class="form-group d-flex align-items-center"
                >
                  <label for="category_code">次分類代碼</label>
                  <input
                    id="category_code"
                    v-model="category_detail.categorySubCode"
                    disabled
                    type="text"
                    name="次分類代碼"
                    class="form-control"
                    :class="{'is-invalid': errors.has('detail.次分類代碼')}"
                    data-vv-scope="detail"
                    placeholder="無需輸入"
                  >
                </div>
              </div>
              <div class="col-6">
                <div class="form-group d-flex align-items-center">
                  <label for="category_name">分類名稱</label>
                  <input
                    v-if="modal_content === 'main'"
                    id="category_name"
                    v-model="category_detail.categoryName"
                    v-validate="'required'"
                    type="text"
                    name="分類名稱"
                    class="form-control"
                    :class="{'is-invalid': errors.has('detail.分類名稱')}"
                    data-vv-scope="detail"
                    placeholder="分類名稱"
                  >
                  <input
                    v-if="modal_content === 'sub'"
                    id="category_name"
                    v-model="category_detail.categorySubName"
                    v-validate="'required'"
                    type="text"
                    name="分類名稱"
                    class="form-control"
                    :class="{'is-invalid': errors.has('detail.分類名稱')}"
                    data-vv-scope="detail"
                    placeholder="分類名稱"
                  >
                </div>
              </div>
              <div class="col-6">
                <div class="form-group d-flex align-items-center">
                  <label for="category_name">分類名稱英文</label>
                  <input
                    v-if="modal_content === 'main'"
                    id="category_name_en"
                    v-model="category_detail.categoryNameEn"
                    type="text"
                    name="分類名稱英文"
                    class="form-control"
                    :class="{'is-invalid': errors.has('detail.分類名稱英文')}"
                    data-vv-scope="detail"
                    placeholder="分類名稱英文"
                  >
                  <input
                    v-if="modal_content === 'sub'"
                    id="category_name_en"
                    v-model="category_detail.categorySubNameEn"
                    type="text"
                    name="次分類名稱英文"
                    class="form-control"
                    :class="{'is-invalid': errors.has('detail.次分類名稱英文')}"
                    data-vv-scope="detail"
                    placeholder="次分類名稱英文"
                  >
                </div>
              </div>
              <div
                v-if="modal_content === 'main'"
                class="col-6"
              >
                <div class="form-group d-flex align-items-center">
                  <label for="category_style">分類樣式</label>
                  <input
                    id="category_style"
                    v-model="category_detail.categoryStyle"
                    v-validate="'required'"
                    type="text"
                    name="分類樣式"
                    class="form-control"
                    :class="{'is-invalid': errors.has('detail.分類樣式')}"
                    data-vv-scope="detail"
                    placeholder="分類樣式"
                  >
                </div>
              </div>
              <div class="col-6">
                <div class="form-group d-flex align-items-center">
                  <label>前台顯示</label>
                  <div class="form-check form-check-inline">
                    <input
                      id="category_optionShowFront_true"
                      v-model="category_detail.optionShowFront"
                      class="form-check-input"
                      type="radio"
                      name="category_optionShowFront"
                      :value="true"
                    >
                    <label
                      class="form-check-label"
                      for="category_optionShowFront_true"
                    >是</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      id="category_optionShowFront_false"
                      v-model="category_detail.optionShowFront"
                      class="form-check-input"
                      type="radio"
                      name="category_optionShowFront"
                      :value="false"
                    >
                    <label
                      class="form-check-label"
                      for="category_optionShowFront_false"
                    >否</label>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group d-flex align-items-center">
                  <label for="category_htmltitle">網頁標題</label>
                  <input
                    id="category_htmltitle"
                    v-model="category_detail.htmlTitle"
                    v-validate="'required'"
                    type="text"
                    name="網頁標題"
                    class="form-control"
                    :class="{'is-invalid': errors.has('detail.網頁標題')}"
                    data-vv-scope="detail"
                    placeholder="網頁標題"
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="form-group d-flex align-items-center">
                  <label for="category_htmlkeywords">網頁關鍵字</label>
                  <input
                    id="category_htmlkeywords"
                    v-model="category_detail.htmlKeywords"
                    v-validate="'required'"
                    type="text"
                    name="網頁關鍵字"
                    class="form-control"
                    :class="{'is-invalid': errors.has('detail.網頁關鍵字')}"
                    data-vv-scope="detail"
                    placeholder="網頁關鍵字"
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label
                    for="category_htmldesc"
                    class="mb-3"
                  >網頁描述</label>
                  <textarea
                    id="category_htmldesc"
                    v-model="category_detail.htmlDesc"
                    v-validate="'required'"
                    name="網頁描述"
                    rows="2"
                    class="form-control"
                    :class="{'is-invalid': errors.has('detail.網頁描述')}"
                    data-vv-scope="detail"
                    placeholder="網頁描述"
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="form-group d-flex align-items-center">
                  <label for="category_title">標題</label>
                  <input
                    id="category_title"
                    v-model="category_detail.title"
                    type="text"
                    name="標題"
                    class="form-control"
                    :class="{'is-invalid': errors.has('detail.標題')}"
                    data-vv-scope="detail"
                    placeholder="標題"
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label
                    for="category_summary"
                    class="mb-3"
                  >摘要</label>
                  <textarea
                    id="category_summary"
                    v-model="category_detail.summary"
                    name="摘要"
                    rows="3"
                    class="form-control"
                    :class="{'is-invalid': errors.has('detail.摘要')}"
                    data-vv-scope="detail"
                    placeholder="摘要"
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="form-group d-flex align-items-center">
                  <label for="category_styleFrom">福貴糕樣式</label>
                  <input
                    id="category_styleFrom"
                    v-model="category_detail.styleFrom"
                    type="text"
                    name="福貴糕樣式"
                    class="form-control"
                    :class="{'is-invalid': errors.has('detail.福貴糕樣式')}"
                    data-vv-scope="detail"
                    placeholder="福貴糕樣式"
                  >
                </div>
              </div>
              <template v-if="modal_content === 'sub'">
                <div class="col-4">
                  <div class="form-group form_input_image">
                    <input
                      ref="files"
                      type="file"
                      name="image375x400"
                      data-name="image375x400"
                      class="form-control"
                      accept="image/jpeg"
                      @change="readURL($event, $event.target)"
                    >
                    <div class="display_image">
                      <div
                        class="image_hint d-flex flex-column align-items-center justify-content-center"
                      >
                        <span class="text-color_primary">+ 加入圖片</span>
                        <span>尺寸:375 x 400 px</span>
                        <span>格式:jpg、jpeg</span>
                      </div>
                      <div class="image_box">
                        <img
                          id="image375x400"
                          :src="category_detail.image375x400"
                          class="img-fluid"
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group form_input_image">
                    <input
                      ref="files"
                      type="file"
                      name="image768x352"
                      data-name="image768x352"
                      class="form-control"
                      accept="image/jpeg"
                      @change="readURL($event, $event.target)"
                    >
                    <div class="display_image">
                      <div
                        class="image_hint d-flex flex-column align-items-center justify-content-center"
                      >
                        <span class="text-color_primary">+ 加入圖片</span>
                        <span>尺寸:768 x 352 px</span>
                        <span>格式:jpg、jpeg</span>
                      </div>
                      <div class="image_box">
                        <img
                          id="image768x352"
                          :src="category_detail.image768x352"
                          class="img-fluid"
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group form_input_image">
                    <input
                      ref="files"
                      type="file"
                      name="image1080x495"
                      data-name="image1080x495"
                      class="form-control"
                      accept="image/jpeg"
                      @change="readURL($event, $event.target)"
                    >
                    <div class="display_image">
                      <div
                        class="image_hint d-flex flex-column align-items-center justify-content-center"
                      >
                        <span class="text-color_primary">+ 加入圖片</span>
                        <span>尺寸:1080 x 495 px</span>
                        <span>格式:jpg、jpeg</span>
                      </div>
                      <div class="image_box">
                        <img
                          id="image1080x495"
                          :src="category_detail.image1080x495"
                          class="img-fluid"
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group form_input_image">
                    <input
                      ref="files"
                      type="file"
                      name="image950x533"
                      data-name="image950x533"
                      class="form-control"
                      accept="image/jpeg,image/png,image/webp,image/gif"
                      @change="readURL($event, $event.target)"
                    >
                    <div class="display_image">
                      <div
                        class="image_hint d-flex flex-column align-items-center justify-content-center"
                      >
                        <span class="text-color_primary">+ 加入圖片</span>
                        <span>尺寸:950 x 533 px(無二)</span>
                        <span>格式:jpg、jpeg、png、gif、webp</span>
                      </div>
                      <div class="image_box">
                        <img
                          id="image950x533"
                          :src="category_detail.image950x533"
                          class="img-fluid"
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group form_input_image">
                    <input
                      ref="files"
                      type="file"
                      name="image766x300"
                      data-name="image766x300"
                      class="form-control"
                      accept="image/jpeg,image/png,image/webp,image/gif"
                      @change="readURL($event, $event.target)"
                    >
                    <div class="display_image">
                      <div
                        class="image_hint d-flex flex-column align-items-center justify-content-center"
                      >
                        <span class="text-color_primary">+ 加入圖片</span>
                        <span>尺寸:766 x 300 px(福貴糕)</span>
                        <span>格式:jpg、jpeg、png、gif、webp</span>
                      </div>
                      <div class="image_box">
                        <img
                          id="image766x300"
                          :src="category_detail.image766x300"
                          class="img-fluid"
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <div
                v-if="modal_content === 'main'"
                class="col-6"
              >
                <div class="form-group form_input_image">
                  <input
                    ref="files"
                    type="file"
                    name="category_image"
                    class="form-control"
                    accept="image/jpeg"
                    @change="readURL($event, $event.target)"
                  >
                  <div class="display_image">
                    <div class="d-flex flex-column align-items-center justify-content-center">
                      <span class="text-color_primary">+ 加入圖片</span>
                      <span>尺寸:180x55 px</span>
                      <span>格式:jpg、jpeg</span>
                    </div>
                    <img
                      id="category_image"
                      :src="category_detail.imageCategory"
                      class="img-fluid"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 p-0">
            <button
              type="button"
              class="btn btn_default"
              data-dismiss="modal"
              aria-label="Close"
              @click.prevent="init_validate()"
            >
              關閉
            </button>
            <button
              v-show="modal_mode === 'create'"
              type="button"
              class="btn btn_primary"
              @click.prevent="create_category(modal_content)"
            >
              建立分類
            </button>
            <button
              v-show="modal_mode === 'edit'"
              type="button"
              class="btn btn_primary"
              @click.prevent="update_category(modal_content)"
            >
              儲存
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters, mapActions } from 'vuex';
import {
  API_GET_PRODUCT_CATEGORY,
  API_GET_CATEGORY_DETAIL,
  API_ADD_MAIN_CATEGORY,
  API_UPDATE_MAIN_CATEGORY,
  API_ADD_SUB_CATEGORY,
  API_UPDATE_SUB_CATEGORY,
  API_UPDATE_CATEGORY_SHOW,
  API_DELETE_CATEGORY,
  API_UPDATE_CATEGORY_ORDER,
} from '@/plugins/api';

export default {
  components: {
    draggable,
  },
  data() {
    return {
      now_page: 'category',
      search: {
        /* 搜尋資料 */
        system: '',
        main_category: '',
      },
      modal_mode: '', /* 彈窗模式 => create 、 edit */
      modal_content: '', /* 彈窗內容 => main 、 sub */
      category_data: [] /* 主次分類資料 */,
      subcategory_data: [] /* 次分類列表 */,
      category_detail: {}, /* 分類詳細資料 */
      dragging: false, /* 拖曳用 */
    };
  },
  computed: {
    ...mapGetters('system', ['system']),
    system_data: {
      get() {
        const vm = this;
        const data = vm.system.slice();
        return data;
      },
    },
  },
  async mounted() {
    const vm = this;
    await vm.$store.dispatch('page_load', true);
    await vm.$store.dispatch('now_page', vm.now_page);
    await vm.get_system();
    await vm.$store.dispatch('page_load', false);
  },
  methods: {
    ...mapActions('system', ['get_system']),
    /* 初始化驗證 */
    async init_validate() {
      const vm = this;
      vm.$validator.pause();
      vm.$nextTick(() => {
        vm.$validator.reset();
        vm.$validator.errors.clear('detail');
        vm.$validator.fields.items.forEach((field) => field.reset());
        vm.$validator.fields.items.forEach((field) => vm.errors.remove(field));
        vm.$validator.resume();
      });
    },
    /* 取得主次分類列表 */
    async get_main_category(value) {
      const vm = this;
      await vm.initial_search();
      const response = await API_GET_PRODUCT_CATEGORY(value);
      const res = response.data;
      // console.log('取得主次分類列表 成功', res);
      if (res.code === '0000') {
        vm.category_data = res.data;
      }
    },
    /* 初始化 搜尋資料 */
    async initial_search() {
      const vm = this;
      vm.subcategory_data = [];
      vm.category_data = [];
    },
    /* 初始化分類資料 */
    async initial_category() {
      const vm = this;
      vm.category_detail = {};
      $('#category_image').attr('src', '');
      await vm.init_validate();
    },
    /* 篩選次分類列表 */
    async get_sub_category(categoryCode) {
      const vm = this;
      // console.log('篩選次分類列表');
      vm.search.main_category = categoryCode;
      vm.category_data.forEach((item) => {
        if (item.categoryCode === categoryCode) {
          vm.subcategory_data = item.items;
        }
      });
    },
    /* 讀取圖片 */
    async readURL(e, input) {
      const targetId = e.target.name || e.target.dataset.name;
      if (input.files.length > 0) {
        input.files.forEach((item) => {
          const reader = new FileReader();
          reader.onload = function onload(e) {
            $(`#${targetId}`).attr('src', e.target.result);
          };
          reader.readAsDataURL(item);
        });
        $(e.target).parent('.form_input_image').css('height', 'auto');
        $(e.target).parent('.form_input_image').css('flex', '0 0 auto');
      }
    },
    async reset_image() {
      $('.form_input_image').css('height', '140px');
      $('.form_input_image').css('flex', '0 0 140px');
    },
    /* 新增分類 */
    async create_category(content) {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('detail');
      if (validateResult) {
        if (content === 'main') {
          const data = new FormData();
          data.append('systemCode', vm.search.system);
          data.append('categoryCode', vm.category_detail.categoryCode);
          data.append('categoryName', vm.category_detail.categoryName);
          data.append('categoryNameEn', vm.category_detail.categoryNameEn);
          data.append('categoryStyle', vm.category_detail.categoryStyle);
          data.append('optionShowFront', vm.category_detail.optionShowFront);
          data.append('htmlTitle', vm.category_detail.htmlTitle);
          data.append('htmlKeywords', vm.category_detail.htmlKeywords);
          data.append('htmlDesc', vm.category_detail.htmlDesc);
          data.append('imageCategory', new File(['imageCategory'], 'imageCategory.jpg'));
          const imageData = Array.from(document.querySelectorAll('.form_input_image input'));
          imageData.forEach((image) => {
            if (image.files.length > 0) {
              data.set('imageCategory', image.files[0], 'imageCategory.jpg');
            }
          });
          const response = await API_ADD_MAIN_CATEGORY(data);
          const res = response.data;
          // console.log('新增主分類 成功', response);
          if (res.code === '0000') {
            vm.$swal({
              icon: 'success',
              title: '成功',
              text: res.text,
              confirmButtonText: '關閉視窗',
            });
            await vm.get_main_category(vm.search.system);
            await vm.toggle_modal();
          }
        } else if (content === 'sub') {
          const data = new FormData();
          data.append('categoryCode', vm.search.main_category);
          data.append('categorySubCode', vm.category_detail.categorySubCode);
          data.append('categorySubName', vm.category_detail.categorySubName);
          data.append('categorySubNameEn', vm.category_detail.categorySubNameEn);
          data.append('optionShowFront', vm.category_detail.optionShowFront);
          data.append('htmlTitle', vm.category_detail.htmlTitle);
          data.append('htmlKeywords', vm.category_detail.htmlKeywords);
          data.append('htmlDesc', vm.category_detail.htmlDesc);
          data.append('title', vm.category_detail.title);
          data.append('summary', vm.category_detail.summary);
          data.append('styleFrom', vm.category_detail.styleFrom);
          const imageData = [...document.querySelectorAll('.form_input_image input')];
          imageData.forEach((image) => {
            if (image.files.length > 0) {
              data.append(`${image.dataset.name}`, image.files[0]);
            } else {
              data.append(`${image.dataset.name}`, null);
            }
          });
          // const data = {
          //   categoryCode: vm.search.main_category,
          //   categorySubCode: vm.category_detail.categorySubCode,
          //   categorySubName: vm.category_detail.categorySubName,
          //   optionShowFront: vm.category_detail.optionShowFront,
          //   htmlTitle: vm.category_detail.htmlTitle,
          //   htmlKeywords: vm.category_detail.htmlKeywords,
          //   htmlDesc: vm.category_detail.htmlDesc,
          // };
          const response = await API_ADD_SUB_CATEGORY(data);
          const res = response.data;
          console.log('新增次分類', response);
          if (res.code === '0000') {
            vm.$swal({
              icon: 'success',
              title: '成功',
              text: res.text,
              confirmButtonText: '關閉視窗',
            });
            await vm.get_main_category(vm.search.system);
            await vm.get_sub_category(vm.search.main_category);
            await vm.toggle_modal();
          }
        }
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 更新分類 */
    async update_category(content) {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('detail');
      if (validateResult) {
        if (content === 'main') {
          const data = new FormData();
          data.append('categoryCode', vm.category_detail.categoryCode);
          data.append('categoryName', vm.category_detail.categoryName);
          data.append('categoryNameEn', vm.category_detail.categoryNameEn);
          data.append('categoryStyle', vm.category_detail.categoryStyle);
          data.append('optionShowFront', vm.category_detail.optionShowFront);
          data.append('htmlTitle', vm.category_detail.htmlTitle);
          data.append('htmlKeywords', vm.category_detail.htmlKeywords);
          data.append('htmlDesc', vm.category_detail.htmlDesc);
          data.append('imageCategory', new File(['imageCategory'], 'imageCategory.jpg'));
          const imageData = Array.from(document.querySelectorAll('.form_input_image input'));
          imageData.forEach((image) => {
            if (image.files.length > 0) {
              data.set('imageCategory', image.files[0], 'imageCategory.jpg');
            }
          });
          const response = await API_UPDATE_MAIN_CATEGORY(data);
          const res = response.data;
          // console.log('更新主分類 成功', response);
          if (res.code === '0000') {
            vm.$swal({
              icon: 'success',
              title: '成功',
              text: res.text,
              confirmButtonText: '關閉視窗',
            });
            await vm.get_main_category(vm.search.system);
            await vm.toggle_modal();
          }
        } else if (content === 'sub') {
          const data = new FormData();
          data.append('categorySubCode', vm.category_detail.categorySubCode);
          data.append('categorySubName', vm.category_detail.categorySubName);
          data.append('categorySubNameEn', vm.category_detail.categorySubNameEn);
          data.append('optionShowFront', vm.category_detail.optionShowFront);
          data.append('htmlTitle', vm.category_detail.htmlTitle);
          data.append('htmlKeywords', vm.category_detail.htmlKeywords);
          data.append('htmlDesc', vm.category_detail.htmlDesc);
          data.append('title', vm.category_detail.title);
          data.append('summary', vm.category_detail.summary);
          data.append('styleFrom', vm.category_detail.styleFrom);
          const imageData = [...document.querySelectorAll('.form_input_image input')];
          imageData.forEach((image) => {
            if (image.files.length > 0) {
              data.set(`${image.dataset.name}`, image.files[0]);
            } else {
              data.append(`${image.dataset.name}`, null);
            }
          });
          // const data = {
          //   categorySubCode: vm.category_detail.categorySubCode,
          //   categorySubName: vm.category_detail.categorySubName,
          //   optionShowFront: vm.category_detail.optionShowFront,
          //   htmlTitle: vm.category_detail.htmlTitle,
          //   htmlKeywords: vm.category_detail.htmlKeywords,
          //   htmlDesc: vm.category_detail.htmlDesc,
          // };
          const response = await API_UPDATE_SUB_CATEGORY(data);
          const res = response.data;
          console.log('更新次分類 成功', response);
          if (res.code === '0000') {
            vm.$swal({
              icon: 'success',
              title: '成功',
              text: res.text,
              confirmButtonText: '關閉視窗',
            });
            await vm.get_main_category(vm.search.system);
            await vm.get_sub_category(vm.search.main_category);
            await vm.toggle_modal();
          }
        }
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 更新分類顯示 */
    async update_category_optionshow(categoryCode, optionShowFront) {
      const vm = this;
      vm.$store.dispatch('page_load', true);
      const data = {
        categoryCode,
        optionShowFront: !optionShowFront,
      };
      await API_UPDATE_CATEGORY_SHOW(data);
      // console.log('更新分類顯示 成功', response);
      await vm.get_main_category(vm.search.system);
      await vm.get_sub_category(vm.search.main_category);
      vm.$store.dispatch('page_load', false);
    },
    /* 取得分類細節 */
    async get_category_detail(categoryCode, content) {
      const vm = this;
      const response = await API_GET_CATEGORY_DETAIL(categoryCode);
      const res = response.data;
      // console.log('取得分類細節 成功', response);
      if (res.code === '0000') {
        await vm.toggle_modal('edit', content);
        vm.category_detail = res.data;
      }
    },
    /* 刪除分類 */
    async delete_category(categoryCode) {
      const vm = this;
      const value = await vm.$swal({
        title: '確定刪除此分類?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: '取消',
        confirmButtonText: '刪除',
      });
      if (value.isConfirmed) {
        vm.$swal({
          title: '已刪除!',
          confirmButtonText: '關閉視窗',
        });
        await API_DELETE_CATEGORY(categoryCode);
        // console.log('刪除分類 成功', response);
        await vm.get_main_category(vm.search.system);
        await vm.get_sub_category(vm.search.main_category);
      }
    },
    /* 顯示 modal 彈窗 */
    async toggle_modal(mode = '', content = '') {
      const vm = this;
      vm.modal_mode = mode;
      vm.modal_content = content;
      $('#category_edit').modal('toggle');
      await vm.reset_image();
      await vm.initial_category();
    },
    /* 更新主分類順序 */
    async update_maincategory_order({ moved }) {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const data = {
        categoryCode: moved.element.categoryCode,
        number: moved.newIndex + 1,
      };
      const response = await API_UPDATE_CATEGORY_ORDER(data);
      const res = response.data;
      if (res.code === '0000') {
        vm.$swal({
          icon: 'success',
          title: '成功',
          text: res.text,
          timer: 1000,
          confirmButtonText: '關閉視窗',
        });
        await vm.get_main_category(vm.search.system);
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 更新次分類順序 */
    async update_subcategory_order({ moved }) {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const data = {
        categoryCode: moved.element.categorySubCode,
        number: moved.newIndex + 1,
      };
      const response = await API_UPDATE_CATEGORY_ORDER(data);
      const res = response.data;
      if (res.code === '0000') {
        vm.$swal({
          icon: 'success',
          title: '成功',
          text: res.text,
          timer: 1000,
          confirmButtonText: '關閉視窗',
        });
        await vm.get_main_category(vm.search.system);
        await vm.get_sub_category(vm.search.main_category);
      }
      await vm.$store.dispatch('page_load', false);
    },
  },
};
</script>
